import { React, View, CenterWrapper, StyleSheet, Page, Image, SEO } from 'lib'
import { graphql } from 'gatsby'
import { PageTitle } from 'components'
import { HTMLStylesDark, Theme } from 'app'
import { articlePageType } from 'types/internalArticles'

function Article({ data }: articlePageType) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const backgroundImage = frontmatter?.bgImage?.childImageSharp?.fluid?.src || frontmatter?.image?.childImageSharp?.fluid?.src || frontmatter.image

  return (
    <Page>
      <SEO doNotTrack title={frontmatter.title} description={frontmatter.description}/>
      <View style={styles.imageInnerWrapper}>
        <Image
          source={backgroundImage}
          alt={frontmatter.title}
          style={styles.imageWrapper}
        />
        <View style={styles.shadow}/>
      </View>
      <CenterWrapper style={styles.wrapper} contentContainerStyle={styles.innerWrapper}>
        <PageTitle title={frontmatter.title} location='News'/>

        <View
          style={styles.body}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </CenterWrapper>
    </Page>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        isBlog
        bgImage {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Article

const styles = StyleSheet.createComponentStyleSheet({
  imageInnerWrapper: {
    ...Theme.flex,
    ...Theme.fullWidth,
    position: 'relative',
    maxHeight: '75vh',
    height: '75vh',
    [Theme.media.down('small')]: {
      height: '40vh',
    },
  },
  shadow: {
    position: 'absolute',
    inset: 0,
    backgroundColor: 'rgb(24, 24, 24, 0.4)',
    zIndex: -1,
  },
  imageWrapper: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    ...Theme.absolute,
    ...Theme.whole,
    zIndex: -2,
  },
  wrapper: {
    backgroundColor: Theme.colors.white,
    position: 'relative',
  },
  innerWrapper: {
    marginTop: Theme.spacing(6),
    marginBottom: Theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    gap: Theme.spacing(3),
    [Theme.media.down('small')]: {
      marginTop: Theme.spacing(3),
      gap: 0,
    },
  },
  body: {
    ...HTMLStylesDark.plainText,
    display: 'flex',
    flexDirection: 'column',
    ol: {
      marginBottom: 0,
    },
    a: {
      marginBottom: 0,
    },
    hr: {
      width: '100%',
      borderRadius: 8,
      borderColor: 'transparent',
      backgroundColor: Theme.colors.grad1,
      opacity: 0.6,
    },
  },
})

